@use '../../../styles.scss' as common;

$background-colour: common.$light-background-colour !default;
$text-colour: common.$jet-black-colour !default;

$background-colour-mobile: common.$opal-green-colour !default;
$text-colour-mobile: common.$light-background-colour !default;

.Snackbar {
    max-width: 450px;
    color: $text-colour;
    background-color: $background-colour;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 8px 16px;
    border-radius: 4px;
    flex-grow: 1;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px, rgba(0, 0, 0, 0.14) 0px 6px 10px 0px, rgba(0, 0, 0, 0.12) 0px 1px 18px 0px;
    z-index: 1400;
    position: fixed;
    right: 70px;
    top: 40px;
    transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transform: none;
    opacity: 1;

    &-hidden {
        opacity: 0;
        transform: translate(1000px);
    }

    &-mobile {
        color: $text-colour-mobile;
        background-color: $background-colour-mobile;
        left: 70px;
        bottom: 40px;
        top: auto;
    }
}
