@use '../styles.scss' as common;

.awareDescription {
    color: common.$danger-colour !important;
}

.qFeatureDescription {
    color: common.$danger-colour !important;
    font-weight: bold !important;
}
