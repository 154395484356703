@use 'sass:color';
@use '../../../styles.scss' as common;

$icon-colour: common.$ocean-blue-colour !default;
$mobile-icon-colour: #fff !default;

$background-hover-accent: color.scale(common.$ocean-blue-colour, $alpha: -95%) !default;
$background-selected-accent: color.scale(common.$ocean-blue-colour, $alpha: -80%) !default;
$small-border-radius: 3px !default;

.BasketItem {
    display: flex;
    align-items: center;
    color: $icon-colour;
    border-radius: $small-border-radius;
    padding: 12px;
    cursor: pointer;

    &:hover {
        background: $background-hover-accent;
        color: $icon-colour;
    }

    &:active {
        background: $background-selected-accent;
        color: $icon-colour;
    }

    &-mobile {
        padding: 15px 11px;

        &,
        &:hover,
        &:active {
            color: $mobile-icon-colour;
            background: transparent;
        }
    }
}
